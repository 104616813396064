import {
  ActionIcon,
  Button,
  Center,
  Flex,
  Menu,
  Paper,
  Text,
  Title,
  Group as MantineGroup,
  Table,
  createStyles,
  Checkbox,
  UnstyledButton,
  Badge,
  Pagination,
  TextInput,
  useMantineTheme,
  Loader,
  Tooltip,
  NativeSelect,
  ScrollArea,
  Grid,
} from '@mantine/core';
import { Container, useMedplum } from '@medplum/react';
import {
  IconChevronDown,
  IconKey,
  IconMailShare,
  IconRefresh,
  IconSearch,
  IconSend,
  IconSortAscending,
  IconSortAscendingLetters,
  IconSortDescending,
  IconSortDescendingLetters,
  IconUser,
  IconUserCheck,
  IconUserX,
} from '@tabler/icons-react';
import React, { useCallback, useMemo, useState } from 'react';
import { useAuthMeStore } from '../../store/useAuthMeStore';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { PatientTableResp, useGetPatients } from '../../queries/user/useGetPatients';
import { Group, HumanName } from '@medplum/fhirtypes';
import { CreateGroup } from '../teams/components/CreateGroup';
import { showNotification } from '@mantine/notifications';
import { SourceGroup, useChangeUsersGroup } from '../../queries/group/useChangeUsersGroup';
import { useUpdateUserAdminStatus } from '../../queries/user/useUpdateUserAdminStatus';
import { QueryKeys } from '../../queries/query-keys';
import moment from 'moment';
import _ from 'lodash';
import { InviteMember } from './components/InviteMember';
import InvitedUsersModal from './components/InvitedUsersModal';
import { useGetStripeProducts } from '../../queries/stripe/useGetStripeProducts';
import { useGetAccount } from '../../queries/account/useGetAccount';
import { useDeactivateUser } from '../../queries/user/useDeactivateUser';
import { useActivateUser } from '../../queries/user/useActivateUser';
import { useNavigate } from 'react-router-dom';

interface Props {}

const NewUsersPage = ({}: Props) => {
  const theme = useMantineTheme();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const medplum = useMedplum();
  const queryClient = useQueryClient();
  const profileId = medplum.getProfile()?.id;
  const navigate = useNavigate();

  //Query filters
  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText, setDebouncedSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(50);
  const [nameSort, setNameSort] = useState<'name' | '-name' | undefined>(undefined);
  const [lastUpdatedSort, setLastUpdatedSort] = useState<'_lastUpdated' | '-_lastUpdated'>('-_lastUpdated');
  const [isInvitedUsersModalOpen, setIsInvitedUsersModalOpen] = useState(false);

  const [isInviteOpen, setisInviteOpen] = useState(false);
  const [selectedCheckBox, setselectedCheckBox] = useState<any>([]);
  const [menuOpen, setMenuOpen] = useState();
  const [assignMenuOpened, setAssignMenuOpened] = useState(false);
  const [assignToValue, setAssignToValue] = useState('');
  const [selectedValues, setSelectedValues] = useState<any>({});
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [currentProduct, setCurrentProduct] = useState<any>();
  // const [subscriptionId, setSubscriptionId] = useState<string | undefined>();
  const [currentProductId, setCurrentProductId] = useState<string | undefined>();
  const [licensesAmount, setLicensesAmount] = useState<string | undefined>();

  const orgId = localStorage.getItem('orgId') ?? useAuthMeStore((s) => s.orgId);
  const userId = useAuthMeStore((s) => s.userId);

  const { isLoading: isAccountLoading } = useGetAccount(orgId as string, {
    onSuccess: (resp) => {
      // setSubscriptionId(resp?.identifier?.find((r) => r?.system === 'subscriptionId')?.value);
      setCurrentProductId(resp?.identifier?.find((r) => r?.system === 'productId')?.value);
    },
  });

  const { isFetched: isFetchedStripeProducts, isLoading: isStripeProductsLoading } = useGetStripeProducts({
    enabled: !!currentProductId,
    refetchOnMount: true,
    onSuccess: (resp) => {
      const currentPlan = resp?.data?.find((p: any) => p?.id === currentProductId);

      setCurrentProduct(currentPlan);
      setLicensesAmount(currentPlan?.metadata?.licensesAmount);
      // setCount(Number(currentPlan?.metadata?.licensesAmount));
    },
  });

  const {
    data: patientData,
    isFetched,
    isLoading,
  } = useGetPatients(page, count, debouncedSearchText, nameSort, lastUpdatedSort, {
    enabled: !!orgId && !!isFetchedStripeProducts,
    refetchOnMount: false,
    retry: false,
    refetchOnWindowFocus: false,
  });
  const allGroups: Group[] = useMemo(() => patientData?.groups, [patientData]);

  function handleSingleCheckboxClick(e: React.ChangeEvent, resource: any): void {
    e.stopPropagation();

    const el = e.target as HTMLInputElement;
    const checked = el.checked;

    if (checked) {
      setselectedCheckBox((prevData: any) => [...prevData, resource]);
    } else {
      setselectedCheckBox((prevValue: any) => prevValue.filter((preVal: any) => preVal.id !== resource.id));
    }
  }

  function handleAllCheckboxClick(e: React.ChangeEvent): void {
    e.stopPropagation();

    const el = e.target as HTMLInputElement;
    const checked = el.checked;

    const pData = [...patientData];

    if (checked) {
      for (let p of pData) {
        setselectedCheckBox((prevData: any) => [...prevData, p]);
      }
    } else {
      for (let p of patientData) {
        setselectedCheckBox((prevValue: any) => prevValue.filter((preVal: any) => preVal.id !== p.id));
      }
    }
  }

  // Define your debounced function
  const debouncedHandleInputChange = _.debounce((value) => {
    setDebouncedSearchText(value);
  }, 1000);

  // Event handler for input change
  const handleSearchTextChange = (event: any) => {
    const value = event.target.value;
    setSearchText(value);
    debouncedHandleInputChange(value);
  };

  const refreshResults = useCallback(async () => {
    await queryClient.resetQueries(QueryKeys.GET_PATIENTS);
    await queryClient.invalidateQueries(QueryKeys.GET_CONSENTS);
  }, [medplum]);

  const { mutateAsync: changeUserAdminStatus } = useUpdateUserAdminStatus({
    onSuccess: async (resp) => {
      const flagResp = resp?.entry?.find((e: any) => e?.resource?.resourceType === 'Flag')?.resource;

      const membershipResp = resp?.entry?.find((e: any) => e?.resource?.resourceType === 'ProjectMembership')?.resource;

      const isLoggedInUser = membershipResp?.user?.reference === `User/${userId}`;

      const hasPatientAccessPolicy =
        membershipResp?.accessPolicy?.reference === 'AccessPolicy/413680a0-1cdc-4b92-990c-e39c160cc100';

      const isAuthenticatedBecameUserRole = isLoggedInUser && flagResp?.status === 'inactive' && hasPatientAccessPolicy;

      if (isAuthenticatedBecameUserRole) {
        showNotification({ message: t('users.user-role-dont-have-access'), color: 'yellow' });
        await medplum.signOut();
      } else {
        refreshResults();
      }
    },
  });

  const handleRoleCheckboxClick = async (role: 'admin' | 'user', data: PatientTableResp) => {
    if (patientData?.patients?.filter((p: any) => p?.isCompanyAdmin === true)?.length <= 1 && role === 'user') {
      showNotification({ color: 'red', message: t('users.at-least-1-admin-required') });
    } else {
      const confirmation = confirm(
        t(`users.are-you-sure-change-role-to-admin`, {
          nameSurname: `${data?.name?.[0]?.given?.[0]} ${data?.name?.[0]?.family}`,
        })
      );
      if (confirmation) {
        await changeUserAdminStatus({ patientIds: [data?.id as string] });
      }
    }
  };

  const { mutateAsync: changeUsersGroup } = useChangeUsersGroup({
    onSuccess: async () => {
      refreshResults();
      showNotification({ color: 'green', message: t('users.user-group-changed-success') });
      setselectedCheckBox([]);
      setAssignToValue('');
    },
    onError: (error: any) => {
      showNotification({ color: 'red', message: error.message });
    },
  });

  const handleSelectChange = async (data: any, selectedGroupId?: any, selectedGroupName?: any) => {
    const selectedValue = selectedGroupName;

    setSelectedValues((prevSelectedValues: any) => ({
      ...prevSelectedValues,
      [data.id]: selectedValue,
    }));

    if (selectedGroupId === 'button') {
      createNewGroup();
    } else {
      const sourceGroup: SourceGroup[] = [
        {
          sourceGroupId: data?.groupId as string,
          patientRefs: [
            {
              reference: `Patient/${data?.id}`,
            },
          ],
        },
      ];

      await changeUsersGroup({
        targetGroupId: selectedGroupId,
        sourceGroup,
      });
    }
  };

  const createNewGroup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
    setisInviteOpen(false);
    setIsInvitedUsersModalOpen(false);
  };

  const handleOpenMenu = (resource: any) => {
    setMenuOpen(resource.id);
  };

  const handleMenuClose = (resource: any) => {
    // @ts-ignore
    setMenuOpen({ [resource.id]: false });
  };

  const handleAssignChange = async (event?: any, data?: any) => {
    if (event === 'button') {
      createNewGroup();
    } else {
      setAssignToValue(data.name);
      if (selectedCheckBox.length === 0) {
        showNotification({ color: 'red', message: `${t('userError')}` });
      } else {
        const sourceGroups = allGroups?.map((group) => {
          return {
            sourceGroupId: group?.id,
            patientRefs: selectedCheckBox
              ?.filter((patient: any) => patient?.groupId === group?.id)
              ?.map((p: any) => ({ reference: `Patient/${p?.id}` })),
          };
        });

        await changeUsersGroup({
          targetGroupId: data?.id,
          sourceGroup: sourceGroups?.filter((group: any) => group?.patientRefs?.length > 0) as SourceGroup[],
        });
      }
    }
  };

  const handleInvite = () => {
    setisInviteOpen(true);
  };

  const handleNameSort = () => {
    setLastUpdatedSort('-_lastUpdated');
    if (nameSort === undefined) {
      setNameSort('name');
    } else if (nameSort === 'name') {
      setNameSort('-name');
    } else if (nameSort === '-name') {
      setNameSort(undefined);
    }
  };

  const handleLastUpdatedSort = () => {
    setNameSort(undefined);
    if (lastUpdatedSort === '-_lastUpdated') {
      setLastUpdatedSort('_lastUpdated');
    } else {
      setLastUpdatedSort('-_lastUpdated');
    }
  };

  const { mutateAsync: activateUser } = useActivateUser({
    onSuccess: () => {
      showNotification({ message: t('users.user-activated'), color: 'green' });
    },
    onError: (error: any) => {
      showNotification({ message: error.message, color: 'red' });
    },
    onSettled: () => {
      refreshResults();
    },
  });

  const { mutateAsync: deactivateUser } = useDeactivateUser({
    onSuccess: async (data, { patientId }) => {
      console.log({ data, patientId });
      if (patientId === profileId) {
        await medplum.signOut();
        navigate('/signin');
      }
      showNotification({ message: t('users.user-deactivated'), color: 'green' });
    },
    onError: (error: any) => {
      showNotification({ message: error.message, color: 'red' });
    },
    onSettled: () => {
      refreshResults();
    },
  });

  const handleDeactivateUser = async (isCompanyAdmin: boolean, patientId: string, patientName: HumanName[]) => {
    console.log({ patientData });

    if (patientData?.patients?.filter((p: any) => p?.isCompanyAdmin === true)?.length <= 1 && isCompanyAdmin) {
      showNotification({ color: 'red', message: t('users.at-least-1-admin-required') });
    } else {
      const confirmation = confirm(
        `Are you sure you want to deactivate user: ${patientName?.[0]?.given?.[0] ?? ''} ${patientName?.[0]?.family}?`
      );
      if (confirmation) {
        await deactivateUser({ patientId });
      }
    }
  };

  const handleActivateUser = async (patientId: string, patientName: HumanName[]) => {
    const confirmation = confirm(
      `Are you sure you want to activate user: ${patientName?.[0]?.given?.[0] ?? ''} ${patientName?.[0]?.family}?`
    );
    if (confirmation) {
      await activateUser({ patientId });
    }
  };

  function isAllSelected(): boolean {
    if (!isFetched || patientData?.length <= 0) {
      return false;
    }

    // const state = stateRef.current;

    if (selectedCheckBox?.length !== patientData?.length) {
      return false;
    } else {
      return true;
    }
  }

  const rows = patientData?.patients?.map((r: PatientTableResp) => (
    <tr key={r?.id}>
      <td>
        <Checkbox
          size="xs"
          checked={selectedCheckBox?.some((c: PatientTableResp) => c?.id === r?.id)}
          onChange={(e) => handleSingleCheckboxClick(e, r)}
        />
      </td>
      <td style={{ minWidth: 200 }}>
        {r?.name?.[0]?.given?.[0] ?? ''} {r?.name?.[0]?.family}
      </td>
      <td>{r?.telecom?.find((t) => t?.system === 'email')?.value}</td>
      <td style={{ minWidth: 140 }}>{moment(r?.meta?.lastUpdated).format('DD MMM YYYY, HH:mm')}</td>
      <td>
        <Menu disabled={!r?.active} shadow="md" width={200}>
          <Menu.Target>
            <UnstyledButton
              sx={{
                cursor: !r?.active ? 'not-allowed' : 'pointer',
              }}
            >
              <Flex align="center">
                {r?.isCompanyAdmin ? (
                  <IconKey color={theme.colors.green[9]} size={20} />
                ) : (
                  <IconUser color={theme.colors.blue[9]} size={22} />
                )}
                <Center ml={12}>
                  <Text
                    color={r?.isCompanyAdmin ? theme.colors.green[9] : theme.colors.blue[9]}
                    fz="sm"
                    fw="inherit"
                    mr={6}
                    sx={{ textTransform: 'capitalize' }}
                  >
                    {r?.isCompanyAdmin ? `${t('common.admin')}` : `${t('common.user')}`}
                  </Text>
                  <IconChevronDown color={r?.isCompanyAdmin ? theme.colors.green[9] : theme.colors.blue[9]} size={16} />
                </Center>
              </Flex>
            </UnstyledButton>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Label>{t('users.select-a-role')}</Menu.Label>
            <Menu.Item onClick={() => handleRoleCheckboxClick('admin', r)} value="admin">
              {t('common.admin')}
            </Menu.Item>
            <Menu.Item onClick={() => handleRoleCheckboxClick('user', r)} value="user">
              {t('common.user')}
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </td>
      <td style={{ minWidth: 200 }}>
        <Menu
          // width={260}
          shadow="xl"
          position="bottom-end"
          transitionProps={{ transition: 'pop-top-right' }}
          opened={menuOpen === r?.id}
          onClose={() => handleMenuClose(r)}
        >
          <Menu.Target>
            <UnstyledButton
              onClick={() => handleOpenMenu(r)}
              sx={{
                width: '100%',
                cursor: !r?.active ? 'not-allowed' : 'pointer',
              }}
              disabled={!r?.active}
            >
              <MantineGroup spacing={7} sx={{ justifyContent: 'space-between' }}>
                <Text size="sm">{selectedValues[r?.id as string] || r?.groupName}</Text>
                {r?.active && <IconChevronDown size={18} stroke={1.5} />}
              </MantineGroup>
            </UnstyledButton>
          </Menu.Target>
          <Menu.Dropdown sx={{ width: '13.25rem!important', maxHeight: '300px', overflowY: 'auto' }}>
            {allGroups?.map((option: Group) => (
              <Menu.Item onClick={(e: any) => handleSelectChange(r, option?.id, option?.name)} key={option?.id}>
                {option?.name}
              </Menu.Item>
            ))}
            <Menu.Item
              onClick={(e: any) => handleSelectChange(r, 'button')}
              sx={{ padding: '10px 5px' }}
              className="btn-create"
            >
              <Button size="sm">{t('teams.create-new-team')}</Button>
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </td>

      <td>
        <Flex align="center" justify="flex-start">
          <Badge
            color={r?.status === 'pending' ? 'orange' : r?.status === 'active' ? 'green' : 'red'}
            variant="outline"
            fw="normal"
          >
            <Text
              fz="sm"
              color={r?.status === 'pending' ? 'orange' : r?.status === 'active' ? 'green' : 'red'}
              sx={{ textTransform: 'capitalize' }}
            >
              {t(`common.${r?.status}`)}
            </Text>
          </Badge>
        </Flex>
      </td>
      <td style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        {r?.status !== 'pending' &&
          (!r?.active ? (
            <Tooltip
              label={t('users.activate-user')}
              sx={{ cursor: r?.status === 'pending' ? 'not-allowed' : 'pointer' }}
            >
              <ActionIcon
                disabled={r?.status === 'pending'}
                onClick={() => handleActivateUser(r?.id as string, r?.name as HumanName[])}
              >
                <IconUserCheck color={theme.colors.green[8]} size={18} />
              </ActionIcon>
            </Tooltip>
          ) : (
            <Tooltip
              label={t('users.deactivate-user')}
              sx={{ cursor: r?.status === 'pending' ? 'not-allowed' : 'pointer' }}
            >
              <ActionIcon
                disabled={r?.status === 'pending'}
                onClick={() => handleDeactivateUser(r?.isCompanyAdmin, r?.id as string, r?.name as HumanName[])}
              >
                <IconUserX color={theme.colors.red[8]} size={18} />
              </ActionIcon>
            </Tooltip>
          ))}
      </td>
    </tr>
  ));

  return (
    <>
      <Paper shadow="xs" m="md">
        <div className={classes.root} data-testid="search-control">
          <Flex px={12} direction="column" sx={{ borderBottom: '1px solid #f3f3f3' }}>
            <Flex py={12} direction="column">
              <Flex py={12} direction="row" align="center" justify="space-between">
                <Title fw="bold" order={2}>
                  {t('users.users')}
                </Title>
                {isFetched && (
                  <Flex direction="row" align="center" justify="flex-end">
                    <Badge variant="light" color="blue" size="lg" style={{ textTransform: 'none' }}>
                      <Flex w={'100%'} align="center" justify="space-between">
                        <Text style={{ fontSize: 12, fontWeight: 'normal' }} variant="text">
                          {`${t('customers.amount-of-licenses')}: ${patientData?.activePatientTotal ?? '0'}/${
                            licensesAmount ?? '0'
                          }`}
                        </Text>
                      </Flex>
                    </Badge>
                  </Flex>
                )}
              </Flex>
              <Text color="grey" size="sm">
                {t('users.users-subheadline', {
                  activeUsers: patientData?.activePatientTotal,
                  inactiveUsers: patientData?.inactivePatientTotal,
                  pendingUsers: patientData?.pendingPatientTotal,
                })}
              </Text>
            </Flex>
            <Grid py={12} sx={{ borderTop: '1px solid #f3f3f3' }}>
              <Grid.Col xs={12} sm={6}>
                <Grid>
                  <Grid.Col xs={12} sm={4}>
                    <TextInput
                      placeholder={`${t('common.search')}...`}
                      icon={<IconSearch size={20} />}
                      value={searchText}
                      mr={{ xs: 0, sm: 12 }}
                      mb={{ xs: 20, sm: 0 }}
                      onChange={handleSearchTextChange}
                    />
                  </Grid.Col>
                  <Grid.Col xs={12} sm={8}>
                    <Flex align="center" direction="row">
                      <Button size="sm" mr={12} leftIcon={<IconSend size={14} />} onClick={handleInvite}>
                        {t('common.invite')}
                      </Button>
                      <Button
                        size="sm"
                        variant="light"
                        mr={12}
                        leftIcon={<IconMailShare size={14} />}
                        onClick={() => setIsInvitedUsersModalOpen(true)}
                      >
                        {t('users.see-invited-users')}
                      </Button>
                      {selectedCheckBox.length > 0 && (
                        <Menu
                          width={260}
                          shadow="xl"
                          position="bottom-end"
                          transitionProps={{ transition: 'pop-top-right' }}
                          opened={assignMenuOpened}
                          onClose={() => setAssignMenuOpened(false)}
                        >
                          <Menu.Target>
                            <Button
                              variant="light"
                              size="sm"
                              disabled={selectedCheckBox.length <= 0}
                              onClick={() => setAssignMenuOpened((o) => !o)}
                              sx={{ cursor: selectedCheckBox.length <= 0 ? 'default' : 'pointer' }}
                              rightIcon={<IconChevronDown size={12} stroke={1.5} />}
                            >
                              <Text size="sm">{assignToValue ? assignToValue : t('table.assign')}</Text>
                            </Button>
                          </Menu.Target>
                          <Menu.Dropdown sx={{ width: '13.25rem!important', maxHeight: '300px', overflowY: 'auto' }}>
                            {allGroups?.map((option: Group) => (
                              <Menu.Item key={option?.id} onClick={(e: any) => handleAssignChange('', option)}>
                                {option?.name}
                              </Menu.Item>
                            ))}
                            <Menu.Item
                              onClick={(e: any) => handleAssignChange('button')}
                              sx={{ padding: '10px 5px' }}
                              className="btn-create"
                            >
                              <Button>{t('teams.create-new-team')}</Button>
                            </Menu.Item>
                          </Menu.Dropdown>
                        </Menu>
                      )}
                    </Flex>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
              <Grid.Col xs={12} sm={6}>
                <Flex direction="row" align="center" justify="flex-end">
                  {patientData && (
                    <Text size="xs" color="dimmed">
                      {page * count - (count - 1)} -{' '}
                      {page * count > patientData?.total ? patientData?.total : page * count} of{' '}
                      {`${patientData?.total}`}
                    </Text>
                  )}
                  <ActionIcon onClick={refreshResults} title="Refresh">
                    <IconRefresh size="1.125rem" />
                  </ActionIcon>
                </Flex>
              </Grid.Col>
            </Grid>
          </Flex>

          {isLoading || isAccountLoading || isStripeProductsLoading ? (
            <Center w="100%" p={20}>
              <Loader />
            </Center>
          ) : (
            <ScrollArea style={{ overflow: 'unset' }}>
              <Table highlightOnHover withColumnBorders striped horizontalSpacing="sm" verticalSpacing="sm">
                <thead>
                  <tr>
                    <th>
                      <Checkbox size="xs" checked={isAllSelected()} onChange={(e) => handleAllCheckboxClick(e)} />
                    </th>
                    <th>
                      <UnstyledButton onClick={handleNameSort}>
                        <Flex align="center" dir="row" justify="space-between">
                          <Text mr={12} fw="bold" fz={14}>
                            {t('table.name-surname')}
                          </Text>
                          {nameSort === '-name' ? (
                            <IconSortDescendingLetters size={18} />
                          ) : nameSort === 'name' ? (
                            <IconSortAscendingLetters size={18} />
                          ) : null}
                        </Flex>
                      </UnstyledButton>
                    </th>
                    <th>
                      <Text mr={12} fw="bold" fz={14}>
                        {t('table.email')}
                      </Text>
                    </th>
                    <th>
                      <UnstyledButton onClick={handleLastUpdatedSort}>
                        <Flex align="center" dir="row" justify="space-between">
                          <Text mr={12} fw="bold" fz={14}>
                            {t('table._lastUpdated')}
                          </Text>
                          {lastUpdatedSort === '-_lastUpdated' ? (
                            <IconSortDescending size={18} />
                          ) : lastUpdatedSort === '_lastUpdated' ? (
                            <IconSortAscending size={18} />
                          ) : null}
                        </Flex>
                      </UnstyledButton>
                    </th>
                    <th>
                      <Text mr={12} fw="bold" fz={14}>
                        {t('table.role')}
                      </Text>
                    </th>
                    <th>
                      <Text mr={12} fw="bold" fz={14}>
                        {t('table.team')}
                      </Text>
                    </th>
                    <th>
                      <Text mr={12} fw="bold" fz={14}>
                        {t('table.status')}
                      </Text>
                    </th>
                    <th style={{ textAlign: 'right' }}>
                      <Text style={{ textAlign: 'end' }} mr={12} fw="bold" fz={14}>
                        {t('table.action')}
                      </Text>
                    </th>
                  </tr>
                </thead>
                <tbody>{rows}</tbody>
              </Table>
            </ScrollArea>
          )}
          {patientData?.patients?.length === 0 && (
            <Container>
              <Center style={{ height: 150 }}>
                <Text size="xl" color="dimmed">
                  {t('common.no-results')}
                </Text>
              </Center>
            </Container>
          )}
          {patientData?.total !== undefined && (
            <Flex direction="row" align="center" justify="space-between" m="md" p="md">
              <Flex />
              <Pagination value={page} onChange={setPage} total={Math.ceil(patientData?.total / count)} />
              <Flex direction="row" align="center" sx={{ alignSelf: 'flex-end' }}>
                <Text size="sm" color="dimmed">
                  {t('table.rows-per-page')}:
                </Text>
                <NativeSelect
                  size="sm"
                  ml={12}
                  value={count}
                  data={['5', '10', '20', '50', '100']}
                  onChange={(e) => setCount(Number(e.target.value))}
                />
              </Flex>
            </Flex>
          )}
        </div>
      </Paper>
      {/* INVITE MEMBER MODAL */}
      <InviteMember
        total={patientData?.total}
        currentProduct={currentProduct}
        open={isInviteOpen}
        closePopup={closePopup}
      />

      {/* INVITED USERS MODAL */}
      <InvitedUsersModal isOpened={isInvitedUsersModalOpen} onClose={closePopup} />

      {/* CREATE GROUP (TEAM) MODAL */}
      <CreateGroup loadResults={refreshResults} open={isPopupOpen} type={'create'} closePopup={closePopup} />
    </>
  );
};

export default NewUsersPage;

const useStyles = createStyles((theme) => ({
  root: {
    maxWidth: '100%',
    overflow: 'auto',
    textAlign: 'left',
    marginBottom: '20px',
  },

  table: {},

  tr: {
    height: '55px',
    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
    },
  },

  th: {
    padding: '0 !important',
  },

  control: {
    width: '100%',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },

  icon: {
    width: 21,
    height: 21,
    borderRadius: 21,
  },
}));

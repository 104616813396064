import React, { useState } from 'react';
import { Grid, Box, Card, Button, LoadingOverlay } from '@mantine/core';
import { useMedplum } from '@medplum/react';
import { EditUser } from './EditUser';
import { useTranslation } from 'react-i18next';
import { useUpdatePatient } from '../../queries/user/useUpdatePatient';
import { Attachment, Patient, Practitioner } from '@medplum/fhirtypes';
import { useUpdatePractitioner } from '../../queries/practitioner/useUpdatePractitioner';
import _ from 'lodash';

export interface UpdatePatientFormData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNo: string;
  photo: Attachment[];
  gender: 'other' | 'male' | 'female' | 'unknown' | undefined;
}

export const User = () => {
  const { t } = useTranslation();
  const medplum = useMedplum();

  //Profile can be Patient or Practitioner
  const profile = medplum.getProfile() as Patient | Practitioner;

  const [formData, setFormData] = useState<UpdatePatientFormData>({
    firstName: profile?.name?.[0]?.given?.[0] ?? '',
    lastName: profile?.name?.[0]?.family ?? '',
    email: profile?.telecom?.find((t) => t?.system === 'email')?.value ?? '',
    phoneNo: profile?.telecom?.find((t) => t?.system === 'phone')?.value ?? '',
    photo: [
      {
        contentType: profile?.photo?.[0]?.contentType ?? '',
        title: profile?.photo?.[0]?.title ?? '',
        url: profile?.photo?.[0]?.url ?? '',
      },
    ],
    gender: profile?.gender ?? undefined,
  });

  const { mutateAsync: updatePatient, isLoading: isUpdatePatientLoading } = useUpdatePatient({
    onSuccess: async (resp) => {
      console.log('Patient Updated', resp);
      const activeLogin = medplum.getActiveLogin();
      if (activeLogin) {
        medplum.setActiveLogin({
          ...activeLogin,
          profile: resp,
        });
      }
    },
  });
  const { mutateAsync: updatePractitioner, isLoading: isUpdatePractitionerLoading } = useUpdatePractitioner();

  const handleEdit = async () => {
    if (profile.resourceType === 'Patient') {
      await handleEditPatient();
    }

    if (profile.resourceType === 'Practitioner') {
      await handleEditPractitioner();
    }
  };

  // This is the case for Company Admins(Patient with Flag(status === 'active'))
  const handleEditPatient = async () => {
    const body: Patient = {
      ...(profile as Patient),
      name: [
        {
          given: [formData.firstName],
          family: formData.lastName,
        },
      ],
      telecom: [
        {
          system: 'email',
          value: formData.email,
        },
        {
          system: 'phone',
          value: formData.phoneNo,
        },
      ],
      contact: [
        {
          telecom: [
            {
              system: 'phone',
              value: formData.phoneNo,
            },
          ],
        },
      ],
      photo: formData.photo ?? undefined,
      gender: formData.gender ?? undefined,
    };

    await updatePatient({
      body,
    });
  };

  // This is the case for Project Admins(Practitioner with ProjectMembershio(admin === true))
  const handleEditPractitioner = async () => {
    const body: Practitioner = {
      ...(profile as Practitioner),
      name: [
        {
          given: [formData.firstName],
          family: formData.lastName,
        },
      ],
      telecom: [
        {
          system: 'email',
          value: formData.email,
        },
        {
          system: 'phone',
          value: formData.phoneNo,
        },
      ],
      photo: formData.photo ?? undefined,
      gender: formData.gender ?? undefined,
    };

    await updatePractitioner({
      body,
    });
  };

  if (isUpdatePatientLoading || isUpdatePractitionerLoading) {
    return <LoadingOverlay visible />;
  }

  return (
    <>
      <Box px="lg" py="sm" sx={{ paddingBottom: '0px !important' }}>
        <Grid mb="md">
          <Grid.Col span={12} lg={9} sx={{ display: 'block', margin: 'auto' }}>
            <Card withBorder radius="md" sx={{ padding: '0px !important' }}>
              <Card.Section p="lg" sx={{ padding: '2rem 2rem !important' }}>
                <EditUser
                  formData={formData}
                  setFormData={(val: UpdatePatientFormData) => setFormData(val)}
                  impactValue={undefined}
                />

                <Grid sx={{ justifyContent: 'end', margin: '20px 0 0' }}>
                  <Button loading={isUpdatePatientLoading || isUpdatePractitionerLoading} ml={12} onClick={handleEdit}>
                    {t('common.save')}
                  </Button>
                </Grid>
              </Card.Section>
            </Card>
          </Grid.Col>
        </Grid>
      </Box>
    </>
  );
};

import { Button, Card, Center, Flex, Group, PasswordInput, Stack, Text, Title, createStyles } from '@mantine/core';
import { badRequest, normalizeOperationOutcome } from '@medplum/core';
import { Narrative, OperationOutcome } from '@medplum/fhirtypes';
import { Form, getErrorsForInput, MedplumLink, useMedplum } from '@medplum/react';
import { IconCircleCheck } from '@tabler/icons-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
  hiddenMobile: {
    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },

  paddingMobile: {
    [theme.fn.smallerThan('sm')]: {
      padding: '25px !important',
    },
  },

  primaryTitle: {
    textAlign: 'left',
    color: '#000',
    float: 'left',
    fontSize: '34px',
    lineHeight: '1.1',
    fontWeight: 300,
    marginBottom: '8px',
  },

  secondaryTitle: {
    color: '#272D41',
    float: 'left',
    fontSize: '20px',
    lineHeight: '1.1',
    fontWeight: 700,
    marginBottom: '8px',
  },

  subTitle: {
    color: '#94a3b8',
    marginTop: '10px',
    letterSpacing: '-0.025em',
    fontSize: '14px',
    fontWeight: 300,
  },
}));

export function SetPasswordPage(): JSX.Element {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { id, secret } = useParams() as { id: string; secret: string };
  const medplum = useMedplum();
  const [outcome, setOutcome] = useState<OperationOutcome>();
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  return (
    <Center w={'100%'} h={'100%'}>
      <Card shadow="xl" withBorder miw={{ xs: '90%', sm: '90%', md: '50%', lg: '36%', xl: '36%' }}>
        <Form
          style={{ width: '100%' }}
          onSubmit={(formData: Record<string, string>) => {
            if (formData.password !== formData.confirmPassword) {
              setOutcome(badRequest(t('auth.passwords-do-not-match'), 'confirmPassword'));
              return;
            }
            setOutcome(undefined);
            const body = {
              id,
              secret,
              password: formData.password,
            };
            medplum
              .post('auth/setpassword', body)
              .then(() => setSuccess(true))
              .catch((err) => {
                if (err?.message === 'Password found in breach database (password)') {
                  const theOutcome = normalizeOperationOutcome(err);
                  if (theOutcome) {
                    theOutcome.text = t('auth.password-must-have') as Narrative;
                    if (theOutcome?.issue?.[0]?.details) {
                      theOutcome.issue[0].details.text = t('auth.password-must-have');
                    }
                  }
                  setOutcome(normalizeOperationOutcome(theOutcome));
                } else {
                  setOutcome(normalizeOperationOutcome(err));
                }
              });
          }}
        >
          <Center sx={{ flexDirection: 'column' }}>
            <img src="../../img/goecofit-favicon.png" style={{ width: '50px' }} />
            <Title className={classes.secondaryTitle} variant="h3" fw={700} mb={24} mt={20}>
              {t('auth.set-password')}
            </Title>
          </Center>
          {!success && (
            <Stack w={'100%'}>
              <PasswordInput
                name="password"
                label={t('auth.new-password')}
                required={true}
                error={getErrorsForInput(outcome, 'password')}
              />
              <PasswordInput
                name="confirmPassword"
                label={t('auth.confirm-new-password')}
                required={true}
                error={getErrorsForInput(outcome, 'confirmPassword')}
              />
              <Group position="right" mt="xl">
                <Button type="submit">{t('auth.set-password')}</Button>
              </Group>
            </Stack>
          )}
          {success && (
            <Flex direction="column" align="center" justify="center">
              <IconCircleCheck strokeWidth={1} color={'#2F738E'} width={120} height={120} />
              <Text my={12} align="center" w="100%" fw="bold" size="lg">
                {t('auth.password-set')}
              </Text>
              <Text align="center" w="100%" size="md">
                {t('auth.you-can-now')}&nbsp;
                <MedplumLink fw="bold" underline onClick={() => navigate('/signin')}>
                  {t('auth.sign-in-to-dashboard')}.
                </MedplumLink>
              </Text>
              <br />
              <Text align="center" w="100%" size="md">
                {t('auth.if-you-are-user')}.
              </Text>
            </Flex>
          )}
        </Form>
      </Card>
    </Center>
  );
}

import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ChangePasswordPage } from './ChangePasswordPage';
import { ErrorPage } from './ErrorPage';
// import { OAuthPage } from './OAuthPage';
// import { RegisterPage } from './RegisterPage';
import { ResetPasswordPage } from './ResetPasswordPage';
import { SetPasswordPage } from './SetPasswordPage';
import { SignInPage } from './SignInPage';
import { User } from './pages/user/User';
import { OrganizationEdit } from './pages/settings/components/OrganizationEdit';
import UserInvite from './pages/user-invite/UserInvite';
import OrganizationSettingsPage from './pages/settings/OrganizationSettingsPage';
import UsersPage from './pages/users/UsersPage';
import TeamsPage from './pages/teams/TeamsPage';
import CustomersPage from './pages/customers/CustomersPage';
// import OverviewPage from './pages/overview/OverviewPage';
import { RegisterPage } from './RegisterPage';
import { useMedplum } from '@medplum/react';

interface Props {}

export function AppRoutes({}: Props): JSX.Element {
  const medplum = useMedplum();
  const isProjectAdmin = medplum.isProjectAdmin();

  return (
    <Routes>
      <Route errorElement={<ErrorPage />}>
        <Route path="/signin" element={<SignInPage />} />
        <Route path="/resetpassword" element={<ResetPasswordPage />} />
        <Route path="/setpassword/:id/:secret" element={<SetPasswordPage />} />
        <Route path="/changepassword" element={<ChangePasswordPage />} />
        <Route path="/customers" element={<CustomersPage />} />
        <Route path="/users" element={<UsersPage />} />
        <Route path="/teams" element={<TeamsPage />} />
        {/* <Route path="/overview" element={<OverviewPage />} /> */}
        <Route path="/edit-organization/:organizationId/:productId" element={<OrganizationEdit />} />
        <Route path="/edit-user" element={<User />} />
        <Route path="/auth/user-invite" element={<UserInvite />} />
        <Route path="/organization-settings" element={<OrganizationSettingsPage />} />
        {/* <Route path="/privacy-policy" element={<PrivacyPolicy />} /> */}
        {/* <Route path="/oauth" element={<OAuthPage />} /> */}
        <Route path="/register" element={<RegisterPage />} />

        {/* INITIAL ROUTING */}
        <Route path="/" element={<Navigate to={!isProjectAdmin ? '/users' : '/customers'} />} />
      </Route>
    </Routes>
  );
}
